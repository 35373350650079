//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { computed, defineComponent, ref, useContext } from '@nuxtjs/composition-api';
import Filters from '@/components/Filters';
import { useAuth } from '~/composables/useAuth';

export default defineComponent({
  // middleware: 'auth',
  components: {
    Filters,
  },
  setup() {
    const { isLoggedIn, user, signOut } = useAuth();
    const { redirect } = useContext();

    const menuItems = [
      {
        icon: 'mdi-apps',
        title: 'Homescreen',
        to: '/',
      },
      {
        icon: 'mdi-format-list-checks',
        title: 'Wyniki',
        to: '/results',
      },
    ];

    const logout = async () => {
      await signOut();
      redirect('/');
    };

    return {
      logout,
      menuItems,
      userEmail: computed(() => user.value?.email),
      isLoggedIn,
      drawer: ref(false),
      rightDrawer: ref(false),
    };
  },
});
