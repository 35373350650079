import { computed, Ref, ref, ssrRef } from '@nuxtjs/composition-api';
import { User } from '@supabase/supabase-js';
import { useLogger } from '../useLogger';
import { supabaseClient } from '~/utils/supbaseClient';

const logger = useLogger();

const user: Ref<User | null> = ssrRef(null);
const loading: Ref<boolean> = ref(false);

const signIn = async (email: string, password: string): Promise<void> => {
  loading.value = true;
  try {
    const { user: loggedUser } = await supabaseClient.auth.signIn({ email, password });
    user.value = loggedUser;
  } catch (err) {
    logger.error(err);
  } finally {
    loading.value = false;
  }
};

const signUp = async (email: string, password: string): Promise<void> => {
  loading.value = true;
  try {
    const { user: registeredUser } = await supabaseClient.auth.signUp({ email, password });
    user.value = registeredUser;
  } catch (err) {
    logger.error(err);
  } finally {
    loading.value = false;
  }
};

const signOut = async (): Promise<void> => {
  loading.value = true;
  try {
    await supabaseClient.auth.signOut();
    user.value = null;
  } catch (err) {
    logger.error(err);
  } finally {
    loading.value = false;
  }
};

const init = async () => {
  loading.value = true;
  try {
    const { user: loggedUser } = await supabaseClient.auth.refreshSession();
    user.value = loggedUser;
  } finally {
    loading.value = false;
  }
};

export const useAuth = () => {
  return {
    user: computed(() => user.value),
    isLoggedIn: computed(() => !!user.value),
    init,
    signIn,
    signUp,
    signOut,
  };
};
