import { render, staticRenderFns } from "./Filters.vue?vue&type=template&id=3108c176&scoped=true&"
import script from "./Filters.vue?vue&type=script&lang=ts&"
export * from "./Filters.vue?vue&type=script&lang=ts&"
import style0 from "./Filters.vue?vue&type=style&index=0&id=3108c176&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3108c176",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VChip } from 'vuetify/lib/components/VChip';
import { VForm } from 'vuetify/lib/components/VForm';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VTextField } from 'vuetify/lib/components/VTextField';
installComponents(component, {VCheckbox,VChip,VForm,VList,VListItem,VTextField})
